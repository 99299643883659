import {CleverdoxContextBootstrap, CleverdoxContextBrowserImpl, DeepLinkSpec} from "@sitewaerts/cdx-container-api";
import {BootstrapTools} from "../common/BootstrapTools";

export class CleverdoxContextBrowserBootstrapImpl extends CleverdoxContextBrowserImpl implements CleverdoxContextBootstrap{

    deepLink: DeepLinkSpec;

    private tools: BootstrapTools;

    private _failed:any;

    constructor() {
        super();
        this.tools = new BootstrapTools(this);
    }

    async start() {
        try {
            await this.init();
            await this.initBrowserApp();
        } catch (e) {
            this._failed = e;
            throw e;
        }
    }

    isFailed():boolean{
        return !!this._failed;
    }

    deepLinkHandled():void{
        this.deepLink = null;
        // window.localStorage.removeItem("cdx.deeplink");//
    }


    private async initBrowserApp() {

        return this.initApp(this.bundles, this.packages);
    }


    private async initApp(bundles: Array<string>, packages: Record<string, string>) {

        // if(!this.deepLink) {
        //     const raw = window.localStorage.getItem("cdx.deeplink");
        //     if(raw)
        //         this.deepLink = JSON.parse(raw);
        // }
        // else
        // {
        //     window.localStorage.setItem("cdx.deeplink", JSON.stringify(this.deepLink));
        // }
        //

        await this.tools.initApp(bundles, packages);
    }


    async setupForApplication() {
        // common setup code goes here
    }

    async applicationSuccessfullyStarted(){
        this.logger.log("applicationSuccessfullyStarted");
    }


}

