import {CleverdoxContextBootstrap, CleverdoxEvents, Utils} from "@sitewaerts/cdx-container-api";
import './bootstrap.less';

export class BootstrapTools {
    private utils: Utils;

    constructor(private readonly cdx: CleverdoxContextBootstrap) {
        this.utils = cdx.utils;
    }

    async initApp(bundles: Array<string>, packages: Record<string, string>) {
        const me = this;
        await me.cdx.loadBundle({
            packages: me.toBundlePackages(packages),
            bundles: bundles
        });
        await me.cdx.setupForApplication();
        // (<any>this).fail(this);
        await me.triggerApplicationLoaded();
    }

    async triggerApplicationLoaded() {
        const logger = this.cdx.logger;
        CleverdoxEvents.once(CleverdoxEvents.APPLICATION_STARTED, (event: CustomEvent)=>{
            logger.log("BootstrapTools: application started", event);
        });
        CleverdoxEvents.fire(CleverdoxEvents.APPLICATION_LOADED);
    }

    toBundlePackages(packages: Record<string, string>): Record<string, {paths : Array<string>}> {
        if (!packages)
            return null;
        const me = this;
        const result = {};
        Object.keys(packages).forEach(function (packageName) {
            const path = packages[packageName];
            if (!me.utils.isEmpty(path))
                result[packageName] = {paths : [path]};
        });
        return result;
    }


}
