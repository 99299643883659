import {CleverdoxRuntime} from "@sitewaerts/cdx-container-api";
import {CleverdoxContextBrowserBootstrapImpl} from "./CleverdoxContextBrowserBootstrapImpl";
import {Tools} from "@sitewaerts/cleverdox-commons";

class CleverdoxBrowserBootstrapStarter {
    private static cdx: CleverdoxContextBrowserBootstrapImpl;

    static start() {
        window.removeEventListener('DOMContentLoaded', CleverdoxBrowserBootstrapStarter.start);

        document.body.classList.add("cleverdox")
        document.body.classList.add("browser")

        const cdx = CleverdoxBrowserBootstrapStarter.cdx = new CleverdoxContextBrowserBootstrapImpl();

        const query = Tools.parseQueryFromURL(window.location);
        if(query['deep']){
            cdx.deepLink = {
                uri: query.first('deep'),
                receiver: 'bootstrap',
                ts: Date.now()
            }
        }

        CleverdoxRuntime.setupRuntime(cdx);
        cdx.start().then(
            () => {
                // successfully started
                cdx.logger.log("successfully bootstrapped cleverdox");
            }, (error) => {
                cdx.logger.error("cannot bootstrap cleverdox", error);
                cdx.fatalError("Error", "Cannot startup the cleverdox app. Please check your network connection and retry. If the problem persists, contact support.", error);
            });
    }
}

window.addEventListener('DOMContentLoaded', CleverdoxBrowserBootstrapStarter.start);
